<template lang="pug">
transition(name="fade")
	waiter(v-if="cfg.loading")
page-layout.font.center#change(
	ref="desk"
	@keydown.left.prevent.exact="navTo(prev)"
	@keydown.right.prevent.exact="navTo(next)"
	)
	router-link.page-nav.btn.naked.prev.clickable.abs.t.l(:to="prev") 𐡷 {{ prev }}
	transition.under(name="slide-fade" appear)
		.hint.vapor.abs.t.r.l.alcenter.font.sm(v-if="!cfg.navvy") ⬅️ Try the arrow keys ➡️
	router-link.page-nav.btn.naked.next.clickable.abs.t.r(:to="next") {{ next }} 𐡸
	.mark(v-if="lots[0]")
		.btn.naked.md.ib.skinny.static(v-if="lots[0] === hex.binary") Being
		router-link.font.md(
			v-if="lots[0] === hex.binary"
			:to="{name: 'oracle'}")
			span(v-if="lots[1]") ⇢
			span(v-if="!lots[1]") ꜛ
		router-link.btn.naked.md.skinny.ib(
			v-if="lots[0] === hex.binary && lots[1]"
			:to="'/changes/'+getWenByBin(lots[1])") Becoming
		router-link.btn.naked.md.skinny.ib(
			v-if="lots[1] === hex.binary"
			:to="'/changes/'+getWenByBin(lots[0])") Being
		router-link.font.md(
			v-if="lots[1] === hex.binary"
			:to="{name: 'oracle'}") ⇠
		.btn.naked.md.ib.skinny.static(v-if="lots[1] === hex.binary") Becoming
	h1.font.x2l.flex.mid.mrg0
		spinnable
			line-glyph(:glyph="hex.hexagram" noturn inline size="x8l")
		.flex.col.mid
			hanzi-char(
				v-for="(char, index) in [...hex.names.chinese]"
				:key="symbolize(char)"
				:char="char"
				:pinyin="pinyin[index]"
				place="side"
				size="xl"
				reveal
				)
	h1 {{ hex.names.english }}
	section.numbers
		.flex.space.string
			.datum.kingwen
				dd {{ hex.kingwen }}
				dt King Wen
			.datum.binary
				dd {{ hex.binary.slice(2) }}
				dt Binary
			.datum.octal
				dd {{ hex.octal }}
				dt Octal
			.datum.octal
				dd {{ parseInt(hex.binary.slice(2), 2) }}
				dt Decimal
	.flex.mid.col.string
		pre.judgment.text.md(v-html="adoptOrphans(hex.judgment)")
	.flex.mid.col.string.images
		.flex.space
			.flex.string.col.dyn
				.datum.trigram.flex.string.laze.btw(
					v-for="(tri, index) in trigrams"
					:key="symbolize(tri.name.en)"
					)
					.flex.col.end.more.trivert
						dt {{ titlize(tri.name.en) }}
						dt {{ index === 0 ? "Above" : "Below" }}
					.flex.col.mid.less
						hanzi-char(
							:char="tri.name.zh"
							:pinyin="tri.name.pn"
							size="lg"
							place="over"
							reveal
							)
					.flex.col.mid.less
						line-glyph.second(
							trigram
							:glyph="tri.trigram"
							size="x5l")
			line-glyph.datum.middle.dyn.alcenter.font(
				:glyph="hex.hexagram"
				size="x6l")
		pre.image.text.md(v-html="adoptOrphans(hex.images)")
	hr.dinkus.fleuron.x3l
	.flex.mid.col.string
		h2.btn.naked.xl.clickable(@click="tog('liney')") {{ cfg.liney ? 'All Lines' : 'Changing Lines'}}
		section(
			v-for="line in hex.lines"
			:key="symbolize(line.position)"
			v-show="lineIsChanging(line.position)"
			)
			turnable(ortho)
				icon-base(size="60" iconColor="var(--flair)")
					component(:is="lineIconByNumber(getChangingLine(line.position)?.icon)")
			h4.font.lg Line {{line.position + ': ' + getChangingLine(line.position)?.desc }}
			.flex.mid
				.icon.font.x3l
				icon-base(size="36")
					component(:is="lineIconByNumber(getChangingLine(line.position)?.was)")
				.icon.font.x3l ⇢
				icon-base(size="36")
					component(:is="lineIconByNumber(getChangingLine(line.position)?.is)")
			h5.text.md.em(v-if="line.ruler") The {{ titlize(line.ruler) }} Ruler
			pre.text.md.line {{line.meaning}}
	hr.dinkus.fleuroff.x3l
	h3
		| All Changes
		br
		small
			| by
			a.btn.naked.md.skinny(@click="tog('wenny')") {{ cfg.wenny ? "King Wen Sequence" : "Octal Index"}}
	.grid8
		app-link.btn.naked(
			v-for="h in getHexagrams(cfg.wenny)"
			:key="symbolize(h[1].kingwen)"
			@click="scrollTo('#change')"
			:class="{static: h[1].kingwen === hex.kingwen}, {outline: h[1].kingwen.toString() === getWenByBin(lots[0])}, {outline: h[1].kingwen.toString() === getWenByBin(lots[1])}"
			:to="'/changes/'+h[1].kingwen"
			) {{ (cfg.wenny ? h[1].kingwen : h[1].octal.slice(1)) + " " + h[1].hexagram }}

	hr.dinkus.fleuron.xl

	logo-brand.mrg.mrg2.y(size="x2l" direction="vert" noname)
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, reactive, watchEffect, onMounted, computed } from 'vue';
import { cfg, set, tog } from '../store';
import { cached } from '../store/cache';
import VueScrollTo from 'vue-scrollto';
import { parseTossToBinary } from '../utils/tosses';
import { useHexagrams } from '../composables/hexagrams';
import { useTrigrams } from '../composables/trigrams';
import PageLayout from '../components/Page.vue';
import Waiter from '../components/Waiter.vue';
import AppLink from '../components/AppLink.vue';
import HanziChar from '../components/HanziChar.vue';
import LineGlyph from '../components/LineGlyph.vue';
import Spinnable from '../components/Spinnable.vue';
import Turnable from '../components/Turnable.vue';
import LineGram from '../components/LineGram.vue';
import IconBase from '../icons/IconBase.vue';
import IconSix from '../icons/IconSix.vue';
import IconSeven from '../icons/IconSeven.vue';
import IconEight from '../icons/IconEight.vue';
import IconNine from '../icons/IconNine.vue';
import { symbolize, titlize, lineIconByNumber } from '../utils';

function getPrevHex(id: string): string {
	if (id === '1') {
		return '64';
	} else {
		return (parseInt(id) - 1).toString();
	}
}

function getNextHex(id: string): string {
	if (id === '64') {
		return '1';
	} else {
		return (parseInt(id) + 1).toString();
	}
}

export default defineComponent({
	name: 'ChangePage',
	components: {
		PageLayout,
		AppLink,
		Waiter,
		LineGram,
		LineGlyph,
		HanziChar,
		IconBase,
		Turnable,
		Spinnable,
		IconSix,
		IconSeven,
		IconEight,
		IconNine,
	},
	props: {
		id: {
			type: String,
			default: '00',
		},
	},
	setup(props) {
		const { getHexagrams, getHexagramByWen, getWenByBin } = useHexagrams();
		const { getTrigram } = useTrigrams();

		const hex = ref(getHexagramByWen(props.id));
		const lots = ref(parseTossToBinary(cached.toss));

		const rx = reactive({
			hex,
			lots,
			desk: ref(),
			mousePresent: false,
			touchPresent: false,
			prev: getPrevHex(props.id),
			next: getNextHex(props.id),
			pinyin: computed(() => hex.value.names.pinyin.split(' ')),
		});

		function lineIsChanging(lineNo: number) {
			const lineCode = cached.toss.split('')[lineNo - 1];
			const tossedThisLot = lots.value && lots.value[0] === rx.hex.binary;
			const activeLines = tossedThisLot && (lineCode === '6' || lineCode === '9');
			return activeLines || !cached.toss || cfg.liney;
		}

		const trigrams = computed(() => {
			return Object.values(rx.hex.trigramPair).map(t => getTrigram(t));
		});

		watchEffect(() => {
			rx.prev = getPrevHex(props.id);
			rx.next = getNextHex(props.id);
		});

		onMounted(() => {
			document.addEventListener(
				'mousemove',
				function onMouseMove() {
					// see if there's a mouse in the house
					document.removeEventListener('mousemove', onMouseMove, false);
					rx.mousePresent = true;
				},
				false
			);

			document.addEventListener('touchmove', function onTouchMove() {
				// see if anyone's in touch
				document.removeEventListener('touchmove', onTouchMove, false);
				rx.touchPresent = true;
				set('navvy', true);
			});

			// const gestureZone = rx.desk
			// console.log('gesureZone', gestureZone)
			// if (!gestureZone) return
			// gestureZone.addEventListener('touchstart', handleSwipeStart, false)
			// gestureZone.addEventListener('touchend', handleSwipeEnd, false)
		});

		return {
			cfg,
			tog,
			cached,
			titlize,
			trigrams,
			symbolize,
			getWenByBin,
			getHexagrams,
			lineIsChanging,
			lineIconByNumber,
			scrollTo: VueScrollTo.scrollTo,
			getHexagramByWen,
			...toRefs(rx),
		};
	},
	watch: {
		$route(to) {
			this.hex = this.getHexagramByWen(to.params.id);
		},
	},
	mounted() {
		window.addEventListener('keydown', this.handleArrowNav, true);
	},
	unmounted() {
		window.removeEventListener('keydown', this.handleArrowNav, true);
	},
	methods: {
		getChangingLine(pos: number) {
			if (this.hex?.binary) {
				return this.hex.binary.slice(2).split('').reverse()[pos - 1] === '0'
					? { icon: '6', was: '8', is: '7', desc: 'Yin Firming' }
					: { icon: '9', was: '7', is: '8', desc: 'Yang Opening' };
			} else return;
		},
		navTo(route: string) {
			if (cfg.justCast) return;
			set('navvy', true);
			this.$router.push(route);
		},
		handleArrowNav(e: KeyboardEvent) {
			if (e.key === 'ArrowLeft') {
				e.preventDefault();
				this.$router.push(this.prev);
				set('navvy', true);
			}
			if (e.key === 'ArrowRight') {
				e.preventDefault();
				this.$router.push(this.next);
				set('navvy', true);
			}
		},
		adoptOrphans(text: string): string {
			// if there are multiple words in the string
			// console.log('adopting orphans', text)
			if (text.split(' ').length > 1) {
				const words = text.split(' ');
				const lastWord = words.pop();
				// replace the space before the last word with &nbsp;
				return words.join(' ') + '&nbsp;' + lastWord;
			} else return text;
		},
	},
});
</script>

<style lang="postcss" scoped>
.change {
	display: flex;
	flex-flow: row wrap;
}

dt {
	--font-casual: 0.75;
	--font-mono: 0;
	--font-weight: 555;
	font-size: 0.925em;
	margin-top: 0.125em;
}

dd {
	margin-left: 0;
	font-size: 1.25em;
}

dd + dd {
	margin-top: 0;
}

section {
	@media (min-width: 36rem) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	@media (min-height: 36rem) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

h3 {
	margin-top: 1em;
}

.laze {
	padding: 0 1rem;
	width: 100%;
}

.flex.space {
	align-self: normal;
}

pre.judgment,
pre.image,
pre.line {
	line-height: var(--pleading);
}

dl + dl:before {
	content: '⤐';
	margin: 0 0 1em;
	font-size: 3rem;
	display: block;
}

.page-nav.btn.naked {
	padding: 0.125em 0.5em;
	margin: 0.5rem;
	text-decoration: none;
	top: 2rem;
	&.l {
		left: 6rem;
	}
	&.r {
		right: 6rem;
	}
}

.hint {
	padding: 1em;
}

.numbers .datum {
	margin: 0 2.5vw;
}

.datum dt {
	font-size: 82.4%;
}

.trigram .col {
	margin: 0 0.5rem;
}

.triglyph.second {
	margin-top: 0;
}

h5 {
	margin: 1em 0;
}

.images .trivert {
	margin: 0.5em 0.5em 0;
}
.images .trigram {
	margin-bottom: 0.25rem;
}

.grid8 {
	display: flex;
	flex-flow: row wrap;
}

@media (min-width: 36rem) {
	.grid8 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		row-gap: 0.5em;
		column-gap: 0.5em;
	}
}
</style>
