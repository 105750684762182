<template lang="pug">
h2.callout.font.center 🚧 More coming soon 🧙‍♂️
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'ComingSoon',
});
</script>

<style lang="postcss"></style>
