export const assets = {
	robotsTxt: 'robots.txt',
	iconSvgPath: 'icon.svg',
	faviconPath: 'favicon.ico',
	icon16Path: 'favicon-16x16.png',
	icon32Path: 'favicon-32x32.png',
	appleIconPath: 'apple-touch-icon.png',
	safariSvgPath: 'safari-pinned-tab.svg',
};

export default {
	root: '/',
	base: '/',
	lang: 'en',
	ogLanguage: 'en_US',
	siteLanguage: 'en-US',
	shortName: 'Nameless',
	longName: 'Nameless Oracle',
	title: 'Nameless Quest ✵ Yìjīng Oracle',
	subtitle: 'A Paean to 易經, I Ching, the Classic of Changes',
	description:
		'A digital oracle, based on the Classic Book of Change, the I Ching (or Yìjīng), who receives burning questions and offers perspective on the true nature of the given moment and how it might unfold. Built for any modern browser, and available as a progressive web app to be downloaded and used offline.',
	categories: 'metaphysical oracle and divination tool',
	image:
		'https://og.easeness.biz/Nameless%20%E2%80%A2%20I%20Ching.png?theme=dark&md=1&fontSize=125px&images=https%3A%2F%2Fatmanaut.sirv.com%2Flogos%2Fbagua-white-mono.svg&widths=450&heights=450',
	backgroundColor: '#E4EDF1',
	themeColor: '#E21D9D',
	statusBarStyle: 'black-translucent',
	author: {
		name: 'Kerry Snyder',
		github: '@krry',
		twitter: '@kerryourself',
		email: 'kerry@easeness.biz',
	},
	manifest: 'manifest.webmanifest',
};
