<template lang="pug">
Page.gratitude
	Spinnable.mrg.mrg2.t
		IconBase(viewBox="0 0 1000 1250" size="128" iconColor="var(--ink)")
			IconCandles
	h1.head.x3l Gratitude
	Busking
	p.text.lg
		| To all those who make this possible…
	section
		h3.head.xxl Thanks
		h4.font.lg to these wonderful photographers…
		dl.photos
			dt
				AppLink._blank(to="https://unsplash.com/@philipgraves97") Philip Graves
			dd
				img.thumb(src="/bg/sm/sea1.jpg")
			dd
				img.thumb(src="/bg/sm/sea2.jpg")
			dd
				img.thumb(src="/bg/sm/sea3.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@felix_mittermeier") Felix Mittermeier
			dd
				img.thumb(src="/bg/sm/night1.jpg")
			dd
				AppLink(to="https://unsplash.com/photos/L4-16dmZ-1c")
					img.thumb(src="/bg/sm/sea5.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@davehoefler") Dave Hoefler
			dd
				AppLink(to="https://unsplash.com/photos/4pvfpUkmR6I")
					img.thumb(src="/bg/sm/day1.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@jplenio") Johannes Plenio
			dd
				AppLink(to="https://unsplash.com/photos/wp7qyfyJBj0")
					img.thumb(src='/bg/sm/land4.jpg')
			dd
				AppLink(to="https://unsplash.com/photos/V9Zvw_fOGqA")
					img.thumb(src='/bg/sm/sea4.jpg')
			dd
				AppLink(to="https://unsplash.com/photos/RwHv7LgeC7s")
					img.thumb(src='/bg/sm/land5.jpg')
			dt
				AppLink._blank(to="https://unsplash.com/@allain_sidd21") Allain Siddiqui
			dd
				img.thumb(src="/bg/sm/land1.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@mjliao") Michael L
			dd
				img.thumb(src="/bg/sm/land2.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@veeterzy") Veeterzy
			dd
				img.thumb(src="/bg/sm/land3.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@dpascoa") Daniel Páscoa
			dd
				AppLink(to="https://unsplash.com/photos/tjiPN3e45WE")
					img.thumb(src="/bg/sm/day2.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@dbmartin00") David Brooke Martin
			dd
				img.thumb(src="/bg/sm/day3.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@kaushikpanchal") Kaushik Panchal
			dd
				AppLink(to="https://unsplash.com/photos/0juC5JIhPks")
					img.thumb(src="/bg/sm/day4.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@sadswim") Ian Dooley
			dd
				AppLink(to="https://unsplash.com/photos/DuBNA1QMpPA")
					img.thumb(src="/bg/sm/day5.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@jeremythomasphoto") Jeremy Thomas
			dd
				img.thumb(src="/bg/sm/night2.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@luca42") Luca Baggio
			dd
				img.thumb(src="/bg/sm/night3.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@vincentiu") Vincentiu Solomon
			dd
				AppLink(to="https://unsplash.com/photos/ln5drpv_ImI")
					img.thumb(src="/bg/sm/night4.jpg")
			dt
				AppLink._blank(to="https://unsplash.com/@rodi01") Rodrigo Soares
			dd
				AppLink(to="https://unsplash.com/photos/SCvlb1FWeuY")
					img.thumb(src="/bg/sm/night5.jpg")
		h3
			| And thanks to&nbsp;
			AppLink._blank(to="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText") Unsplash
			|  for declaring open season on stock photography.
	section.pad.pads.y
		h3.head.xxl Thanks
		h4.font.lg
			| to the I Ching scholars, enthusiasts, and custodians
			br
			| throughout the ages, especially…
		ul.ls
			li
				p.font.alleft
					| Stuart M. Anderson, whose efforts at&nbsp;
					AppLink._blank(to="http://www.russellcottrell.com/VirtualYarrowStalks/downloads/4coinmethod.pdf") Bringing the Yarrow and Coin Methods into Agreement
					|  provide the lion's share of the probability engine here in Nameless.
					| And thanks to Russell Cottrell for hosting that paper, and for the myriad explorations, links, and perspectives on the I Ching; gems like&nbsp;
					AppLink._blank(to="http://www.russellcottrell.com/VirtualYarrowStalks/VirtualYarrowStalks.htm") Virtual Yarrow Stalks
					| ,&nbsp;
					AppLink._blank(to="http://www.russellcottrell.com/VirtualYarrowStalks/anotherFractalIChing.htm") Fractal I Ching maps
					| , and&nbsp;
					AppLink._blank(to="http://www.russellcottrell.com/VirtualYarrowStalks/TakashimaEkidan.htm") the Takashima Ekidan translation
					| .
			li
				p.font.alleft
					| Hilary Barrett, whose&nbsp;
					AppLink._blank(to="https://www.onlineclarity.co.uk/") Online Clarity
					|  I've only just discovered, and from whom I shall learn multitudes about the ways of&nbsp;
					strong Yì
					| .
			li
				p.font.alleft
					| Zelda Hessler (@Velfi) who provides&nbsp;
					AppLink._blank(to="https://github.com/Velfi/i-ching") the I Ching as a Rust library
					| , which entertained my daily practice in the early days. It was then that I first wished for cards and tiles and what you see here.
			li
				p.font.alleft
					| Steve VanDevender who has&nbsp;
					AppLink._blank(to="http://hexadecimal.uoregon.edu/ching/src.html") sourced the Wilhelm-Baynes translation/interpretation of the I Ching
					|  and provided it to the WWW.
	section.flex.col.string
		h3.head.xxl Thanks
		h4.font.lg to the vector artists who made these rad icons…
		dl
			dt
				AppLink._blank(to="https://thenounproject.com/LSEdesigns/") LSE Designs
				|  for&nbsp;
				AppLink._blank(to="https://thenounproject.com/LSEdesigns/collection/a-wizards-world/") A Wizard's World
			dd.flex.string.mid.pad.pads.y.wrap
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconCauldron
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconCandles
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconCrystalBall
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconOpenBook
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconSpellBook
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconScroll
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconWizardsHat
				IconBase(viewBox="0 0 1000 1250" size="84")
					IconCrescentMoon
					//- IconCrystals
					//- IconMagicStaff
					//- IconMagicWand
			dt
				AppLink._blank(to="https://thenounproject.com/jeremy.j.bristol/") Jeremy J Bristol
				|  for&nbsp;
				AppLink._blank(to="https://thenounproject.com/jeremy.j.bristol/uploads/") Assorted Drawrings
			dd.flex.string.mid.pad.pads.y
				IconBase(viewBox="0 0 100 125" size="64")
					IconPeaceSign
				IconBase(viewBox="0 0 100 125" size="64")
					IconPenroseTriangle
				IconBase(viewBox="0 0 100 125" size="64")
					IconThreeLegs
				IconBase(viewBox="0 0 100 125" size="64")
					IconLooptyLoop
			dt
				AppLink._blank(to="https://thenounproject.com/microdotgraphic/") Jino
				|  for&nbsp;
				AppLink._blank(to="https://thenounproject.com/microdotgraphic/collection/business-finance/") Coin
			dd.flex.string.mid.pad.pads.y
				IconBase(viewBox="0 0 512 640" size="84")
					IconCoin
			dt
				AppLink._blank(to="https://thenounproject.com/Flatart/") Flatart
				|  for&nbsp;
				AppLink._blank(to="https://thenounproject.com/Flatart/collection/mardi-gras-bold-line/?i=3167086") Carnival Coin
			dd.flex.string.mid.pad.pads.y
				IconBase(viewBox="0 0 520 650" size="84")
					IconCarnivalCoin
			dt
				AppLink._blank(to="https://thenounproject.com/andydoane/") Andy Doane
				|  for&nbsp;
				AppLink._blank(to="https://thenounproject.com/andydoane/collection/miracle/") Miracle
			dd.flex.string.mid.pad.pads.y
				IconBase(viewBox="0 0 100 125" size="84")
					IconMiracle
			dt
				AppLink._blank(to="https://thenounproject.com/andydoane/") Alexandre Panasovskyi
				|  for&nbsp;
				AppLink._blank(to="https://thenounproject.com/term/coin-flipping/2307580/") Coin Flip
			dd.flex.string.mid.pad.pads.y
				IconBase(viewBox="0 0 50000 62500" size="192")
					IconCoinFlip
		p.text.center
			| And thanks to&nbsp;
			AppLink._blank(to="https://thenounproject.com/") the Noun Project
			|  for helping us
			br
			| share scalable, dynamic drawings of objects.
	section
		h3.head.xl And last but most of all, thanks
		.flex
			h4.mrg.mrg1.x.y.half.font.lg To Elysa and Zephyr
			h4.mrg.mrg1.x.y.text.md.alleft.half Your smiles and wiggles make my dreams come true every day.
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Page from '../components/Page.vue';
import AppLink from '../components/AppLink.vue';
import Spinnable from '../components/Spinnable.vue';
import Busking from '../components/Busking.vue';
import IconBase from '../icons/IconBase.vue';
import IconCoin from '../icons/IconCoin.vue';
import IconScroll from '../icons/IconScroll.vue';
import IconMiracle from '../icons/IconMiracle.vue';
import IconCandles from '../icons/IconCandles.vue';
import IconOpenBook from '../icons/IconOpenBook.vue';
import IconCauldron from '../icons/IconCauldron.vue';
import IconCoinFlip from '../icons/IconCoinFlip.vue';
import IconSpellBook from '../icons/IconSpellBook.vue';
import IconThreeLegs from '../icons/IconThreeLegs.vue';
import IconPeaceSign from '../icons/IconPeaceSign.vue';
import IconWizardsHat from '../icons/IconWizardsHat.vue';
import IconLooptyLoop from '../icons/IconLooptyLoop.vue';
import IconCrystalBall from '../icons/IconCrystalBall.vue';
import IconCarnivalCoin from '../icons/IconCarnivalCoin.vue';
import IconCrescentMoon from '../icons/IconCrescentMoon.vue';
import IconPenroseTriangle from '../icons/IconPenroseTriangle.vue';

export default defineComponent({
	name: 'GratitudePage',
	components: {
		Page,
		AppLink,
		Spinnable,
		Busking,
		IconBase,
		IconCauldron,
		IconMiracle,
		IconCandles,
		IconCrystalBall,
		IconOpenBook,
		IconSpellBook,
		IconScroll,
		IconWizardsHat,
		IconCrescentMoon,
		IconLooptyLoop,
		IconPenroseTriangle,
		IconPeaceSign,
		IconThreeLegs,
		IconCarnivalCoin,
		IconCoin,
		IconCoinFlip,
	},
	data() {
		return {
			title: 'Thanks and Credits',
		};
	},
});
</script>

<style lang="postcss" scoped>
.page.about {
	background-color: var(--silk);
}

dd {
	margin-left: 0;
}

.photos dt {
	text-align: center;
	margin: 2.5rem 0 0.5rem;
	--font-casual: 0.75;
	--font-mono: 0;
	--font-weight: 666;
	font-size: 1.25em;
}

.thumb {
	max-width: 100%;
	width: 600px;
	margin-bottom: 2rem;
}
</style>
